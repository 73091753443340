'use client';

import { useAuth } from '@/context/AuthContextProvider';
import Logo from '@/public/logo.svg';
import UserPlaceholderImg from '@/public/user-placeholder.svg';
import { getHelp } from '@/utils/helpers';
import { Close, Logout, Menu, QuestionMark } from '@mui/icons-material';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
import Toolbar from '@mui/material/Toolbar';
import { useTheme } from '@mui/material/styles';
import Image from 'next/image';
import Link from 'next/link';
import React, { useState } from 'react';

interface AppBarProps {
  links: { text: string; href: string; icon: React.ElementType }[];
}
const PLACEHOLDER_IMAGE = UserPlaceholderImg.src;

const COMPANY_LOGO_BASE_URL =
  'https://kotini-public.s3.eu-west-1.amazonaws.com/images/company/logo/thumbnail';

/**
 * Component: Side Bar
 */
export default function AppBarV2({ links }: AppBarProps) {
  const { company } = useAuth();
  const { logout } = useAuth();
  const theme = useTheme();
  const [minimizeDrawer, setMinimizeDrawer] = useState(true);

  const companyLogoUrl = `${COMPANY_LOGO_BASE_URL}/${company}.png`;

  return (
    <>
      <Box
        sx={{
          padding: 1,
          borderRadius: '24px',
          backgroundColor: theme.palette.background.nav,
          position: 'relative',
          height: 'fit-content',
          mt: 2,
        }}
      >
        <Toolbar
          sx={{
            padding: 0,
            pl: 2,
            minHeight: 'fit-content',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Link href={'/'} style={{ width: '120px', height: '32px' }}>
            <Image src={Logo} alt="Kotini Logo" width={120} height={32} />
          </Link>

          <Stack direction="row" gap={1} alignItems="center">
            <Link href={'/account/personal'}>
              <Avatar
                variant="rounded"
                src={company ? companyLogoUrl : PLACEHOLDER_IMAGE}
              />
            </Link>

            <IconButton
              color="secondary"
              size="large"
              edge="start"
              aria-label="menu"
              sx={{ flexGrow: 0, height: '64px', width: '64px' }}
              onClick={() => setMinimizeDrawer(false)}
            >
              <Menu />
            </IconButton>
          </Stack>
        </Toolbar>
      </Box>
      <Drawer
        sx={{
          width: '100%',
          flexShrink: 0,
          display: 'flex',
          flexDirection: 'column',
          boxSizing: 'border-box',
          '& .MuiDrawer-paper': {
            padding: 2,
            backgroundColor: theme.palette.background.paper,
            width: '100%',
            zIndex: 90, // explore theme handling of z-indices of layout
            display: 'flex',
            alignItems: 'center',
          },
        }}
        anchor="top"
        open={!minimizeDrawer}
        onClick={() => setMinimizeDrawer(true)}
      >
        <List
          sx={{
            width: '100%',
            '& .MuiButtonBase-root': {
              height: '64px',
              padding: '0 16px',
              gap: '8px',
              borderRadius: 2,
              color: theme.palette.primary.main,
            },
            '& .MuiListItemIcon-root': {
              minWidth: '24px',
              color: theme.palette.primary.main,
            },
          }}
          disablePadding
        >
          {links.map(({ text, href, icon: Icon }) => (
            <Link href={href} key={text}>
              <ListItem disablePadding>
                <ListItemButton color="primary">
                  <ListItemIcon>
                    <Icon />
                  </ListItemIcon>
                  <ListItemText primary={text} />
                </ListItemButton>
              </ListItem>
            </Link>
          ))}
          <ListItem key="get-help" disablePadding>
            <ListItemButton key="get-help" onClick={() => getHelp('get-help')}>
              <ListItemIcon key="get-help">
                <QuestionMark />
              </ListItemIcon>
              <ListItemText primary="Help" />
            </ListItemButton>
          </ListItem>
          <ListItem key="logout" disablePadding>
            <ListItemButton key="logout" onClick={() => logout()}>
              <ListItemIcon key="logout">
                <Logout />
              </ListItemIcon>
              <ListItemText primary="Logout" />
            </ListItemButton>
          </ListItem>
          <ListItem key="close" disablePadding>
            <ListItemButton key="close" onClick={() => setMinimizeDrawer(true)}>
              <ListItemIcon key="close">
                <Close />
              </ListItemIcon>
              <ListItemText primary="Close menu" />
            </ListItemButton>
          </ListItem>
        </List>
      </Drawer>
    </>
  );
}
