'use client';

import { useAuth } from '@/context/AuthContextProvider';
import LogoSquare from '@/public/logo-square.svg';
import Logo from '@/public/logo.svg';
import UserPlaceholderImg from '@/public/user-placeholder.svg';
import { getHelp } from '@/utils/helpers';
import { ChevronLeft, ChevronRight, QuestionMark } from '@mui/icons-material';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Skeleton from '@mui/material/Skeleton';
import Tooltip from '@mui/material/Tooltip';
import { Theme, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Image from 'next/image';
import Link from 'next/link';
import { useEffect, useState } from 'react';
interface SideBarProps {
  links: { text: string; href: string; icon: React.ElementType }[];
  linksBottom?: { text: string; href: string; icon: React.ElementType }[];
}

const PLACEHOLDER_IMAGE = UserPlaceholderImg.src;

const COMPANY_LOGO_BASE_URL =
  'https://kotini-public.s3.eu-west-1.amazonaws.com/images/company/logo/thumbnail';

/**
 * Component: Side Bar
 */
export default function SideBarV2({ links, linksBottom }: SideBarProps) {
  const isSm = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const { givenName, lastName, company } = useAuth();
  const theme = useTheme();
  const [minimizeSidebar, setMinimizeSidebar] = useState(isSm);

  useEffect(() => {
    setMinimizeSidebar(isSm);
  }, [isSm]);

  const companyLogoUrl = `${COMPANY_LOGO_BASE_URL}/${company}.png`;
  const name = `${givenName} ${lastName}`;

  return (
    <Box
      sx={{
        width: 'fit-content',
        flexShrink: 0,
        flexGrow: 0,
        backgroundColor: theme.palette.background.nav,
        display: 'flex',
        flexDirection: 'column',
        boxSizing: 'border-box',
        borderRadius: 4,
        padding: 2,
        zIndex: 90, // explore theme handling of z-indices of layout
        alignItems: 'center',
        maxHeight: 'calc(100svh - 32px)',
        position: 'sticky',
        my: 2,
      }}
    >
      <Box
        sx={{
          flexGrow: 1,
        }}
      >
        <Box sx={{ px: 2, justifyContent: 'center', display: 'flex' }}>
          <Link href="/" style={{ width: 'fit-content', height: '50px' }}>
            {minimizeSidebar ? (
              <Image
                src={LogoSquare}
                alt="Kotini Logo Icon"
                width={32}
                height={50}
              />
            ) : (
              <Image src={Logo} alt="Kotini Logo" width={120} height={50} />
            )}
          </Link>
        </Box>
        <List
          sx={{
            width: '100%',
            '& .MuiButtonBase-root': {
              height: '64px',
              padding: '0 20px',
              gap: '8px',
              borderRadius: 2,
              color: theme.palette.primary.main,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            },
            '& .MuiListItemIcon-root': {
              minWidth: '24px',
              color: theme.palette.primary.main,
            },
          }}
          disablePadding
        >
          {links.map(({ text, href, icon: Icon }) => (
            <Link href={href} key={text}>
              <Tooltip title={minimizeSidebar ? text : null} placement="right">
                <ListItem disablePadding>
                  <ListItemButton color="primary">
                    <ListItemIcon>
                      <Icon />
                    </ListItemIcon>
                    {!minimizeSidebar && <ListItemText primary={text} />}
                  </ListItemButton>
                </ListItem>
              </Tooltip>
            </Link>
          ))}

          <Tooltip title={minimizeSidebar ? 'Help' : null} placement="right">
            <ListItem key="get-help" disablePadding>
              <ListItemButton
                key="get-help"
                onClick={() => getHelp('get-help')}
              >
                <ListItemIcon key="get-help">
                  <QuestionMark />
                </ListItemIcon>
                {!minimizeSidebar && <ListItemText primary="Help" />}
              </ListItemButton>
            </ListItem>
          </Tooltip>
        </List>
      </Box>

      <List
        sx={{
          width: '100%',
          '& .MuiButtonBase-root': {
            height: '64px',
            padding: '0 20px',
            gap: '8px',
            borderRadius: 2,
            color: theme.palette.primary.main,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          },
          '& .MuiListItemIcon-root': {
            minWidth: '24px',
            color: theme.palette.primary.main,
          },
        }}
        disablePadding
      >
        {linksBottom &&
          linksBottom.map(({ text, href, icon: Icon }) => (
            <Link href={href} key={text}>
              <Tooltip title={minimizeSidebar ? text : null} placement="right">
                <ListItem key={text} disablePadding>
                  <ListItemButton key={text}>
                    <ListItemIcon key={text}>
                      <Icon key={text} />
                    </ListItemIcon>
                    {!minimizeSidebar && <ListItemText primary={text} />}
                  </ListItemButton>
                </ListItem>
              </Tooltip>
            </Link>
          ))}
        <Link href={'/account/personal'}>
          <Tooltip title={minimizeSidebar ? name : null} placement="right">
            <ListItem key="avatar" disablePadding>
              <ListItemButton key="avatar">
                <ListItemAvatar
                  sx={{ minWidth: '24px', width: '24px', height: '24px' }}
                >
                  <Avatar
                    sx={{
                      width: '24px',
                      height: '24px',
                    }}
                    variant="rounded"
                    src={company ? companyLogoUrl : PLACEHOLDER_IMAGE}
                  />
                </ListItemAvatar>
                {!minimizeSidebar && (
                  <ListItemText
                    sx={{
                      textWrap: 'nowrap',
                      textOverflow: 'ellipsis',
                      maxWidth: '96px',
                      '& .MuiTypography-root': {
                        textWrap: 'nowrap',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                        width: '100%',
                      },
                    }}
                  >
                    {name ? name : <Skeleton variant="text" />}
                  </ListItemText>
                )}
              </ListItemButton>
            </ListItem>
          </Tooltip>
        </Link>
        <Tooltip title={minimizeSidebar ? 'Expand' : null} placement="right">
          <ListItem key="minimise" disablePadding>
            <ListItemButton
              key="minimise"
              onClick={() => {
                setMinimizeSidebar(!minimizeSidebar);
              }}
            >
              <ListItemIcon key="minimise">
                {!minimizeSidebar ? <ChevronLeft /> : <ChevronRight />}
              </ListItemIcon>
              {!minimizeSidebar && <ListItemText primary="Minimise" />}
            </ListItemButton>
          </ListItem>
        </Tooltip>
      </List>
    </Box>
  );
}
