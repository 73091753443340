import {
  show as showIntercom,
  trackEvent as trackEventIntercom,
} from '@/components/_common/Intercom/intercom';

export const getRegistrationLink = (redirect: string) => {
  const issuer = process.env.NEXT_PUBLIC_KEYCLOAK_ISSUER || '';
  const clientId = process.env.NEXT_PUBLIC_KEYCLOAK_CLIENT || '';
  const domain = process.env.NEXT_PUBLIC_DOMAIN || '';
  return `${issuer}/protocol/openid-connect/registrations?client_id=${clientId}&response_type=code&scope=openid&redirect_uri=${encodeURIComponent(
    domain
  )}${redirect}`;
};

export const reverseArray = (array: any) => {
  return array
    .slice(0)
    .reverse()
    .map((element: any) => {
      return element;
    });
};

export const wait = (ms: number) => new Promise((res) => setTimeout(res, ms));

export const isValidUuid = (uuid: string) => {
  return uuid.match(
    /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i
  );
};

export const getHelp = (trackEvent: string) => {
  showIntercom();
  trackEventIntercom(trackEvent);
};

export const isUUID = (uuid: string) => {
  const regex =
    /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[1-5][0-9a-fA-F]{3}-[89abAB][0-9a-fA-F]{3}-[0-9a-fA-F]{12}$/;
  return regex.test(uuid);
};

export const isDebugMode = () => {
  return document.cookie.indexOf('debug=true') !== -1;
};

export function isTaskRedirect(task: { meta?: string }) {
  const taskMeta = task?.meta ? JSON.parse(task.meta) : null;
  if (!taskMeta) return false;
  return taskMeta?.type === 'url';
}

export function colorToBase64Image(color: string): string {
  const canvas = document.createElement('canvas');
  canvas.width = 1;
  canvas.height = 1;
  const context = canvas.getContext('2d');
  if (context) {
    context.fillStyle = color;
    context.fillRect(0, 0, 1, 1);
  }
  return canvas.toDataURL();
}

export function getTaskMetaUrl(task: { meta: string }): string {
  const { url } = JSON.parse(task.meta);
  return url;
}
