'use client';

import { useAuth } from '@/context/AuthContextProvider';
import {
  ApolloClient,
  HttpLink,
  InMemoryCache,
  ApolloProvider as Provider,
  from,
  makeVar,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { useMemo } from 'react';

/**
 * Internal utility function to delay the refetching of a query
 */
const wait = (ms: number) => new Promise((res) => setTimeout(res, ms));

const delayRefetchedQuery = async (observableQuery: any) => {
  await wait(500); // wait 500 ms until the event has been consumed to refetch the query
  observableQuery.refetch();
};

// Reactive Variables
export const currentUserIdVar = makeVar('');

/**
 * Custom ApolloProvider Wrapper that uses useAuth hook to get the accessToken
 */
export const ApolloProvider = ({ children }: { children: React.ReactNode }) => {
  // extract the accessToken and logout function from the AuthContext
  const { accessToken, refresh, logout, login } = useAuth();

  /**
   * Initialize the apollo client instance
   * useMemo is used to prevent the client from being recreated on every render
   */
  const client = useMemo(() => {
    const httpLink = new HttpLink({
      uri: process.env.NEXT_PUBLIC_GRAPHQL_URL,
    });

    const authLink = setContext(async (operation, { headers }) => {
      await refresh();
      return {
        headers: {
          ...headers,
          authorization: accessToken ? `Bearer ${accessToken}` : '',
        },
      };
    });

    const errorLink = onError(({ graphQLErrors, operation, forward }) => {
      if (graphQLErrors) {
        for (const err of graphQLErrors) {
          if (err.extensions) {
            switch (err.extensions.code) {
              case 'UNAUTHENTICATED':
                logout && logout();
            }
          }
          if (err.message) {
            switch (err.message) {
              case 'Authorization header missing':
                login && login();
            }
          }
        }
      }
      return forward(operation);
    });

    return new ApolloClient({
      link: from([errorLink, authLink, httpLink]),
      cache: new InMemoryCache({
        typePolicies: {
          Participant: {
            fields: {
              // Add a read-only field that returns true if the current user is the participant
              isCurrentUser: {
                read(_, { readField }) {
                  const currentUserId = currentUserIdVar();
                  const customerId = readField('customerId');
                  return currentUserId === customerId;
                },
              },
            },
          },
        },
      }),
      defaultOptions: {
        mutate: {
          // if the mutate function has a reftechQueries option, delay the refetching of the query
          onQueryUpdated: delayRefetchedQuery,
        },
      },
    });
  }, [accessToken, refresh]);

  return <Provider client={client}>{children}</Provider>;
};
