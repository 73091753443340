'use client';

import { Dispatch, createContext, useContext, useState } from 'react';

type Document = {
  documentId: string;
  created: string;
  fileName: string;
  displayName: string;
  description: string;
  mimeType: string;
  documentType: string;
  ownerId: string;
  ownerName: string;
  presignedUrl: string;
} | null;
/**
 * documentContextValues defines the structure for the default values of the DocumentContext
 */
interface DocumentContextValues {
  showDocument: boolean;
  setShowDocument: Dispatch<boolean>;
  document: Document;
  setDocument: Dispatch<Document>;
}

/**
 * DocumentContext is the context exposed by the DocumentContextProvider
 */
export const DocumentContext = createContext<DocumentContextValues | undefined>(
  undefined
);

/**
 * The props that must be passed to create the ModalContextProvider
 */
interface DocumentContextProviderProps {
  children: React.ReactNode;
}

/**
 * DocumentContextProvider is responsible for managing the document state
 */
const DocumentContextProvider = (props: DocumentContextProviderProps) => {
  const [showDocument, setShowDocument] = useState<boolean>(false);
  const [document, setDocument] = useState<Document>(null);

  return (
    <DocumentContext.Provider
      value={{
        showDocument,
        setShowDocument,
        document,
        setDocument,
      }}
    >
      {props.children}
    </DocumentContext.Provider>
  );
};

/**
 * Custom useDocumentPreview hook for easy access to the DocumentContext
 */
const useDocumentPreview = () => {
  const documentContext = useContext(DocumentContext);
  if (!documentContext) {
    throw new Error(
      'useDocumentPreview must be used within a DocumentContextProvider'
    );
  }
  return documentContext;
};

export { DocumentContextProvider, useDocumentPreview };
